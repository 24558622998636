.search-box-results > p {
    display: flex;
}

.search-box-results p > a:hover {
    color: var(--highlight-color-light);
}

.search-box-results .game_actions {
    flex: 1 1 auto;
    text-align: right;
}

#game-search-form .search-box.extended .search-box-results {
    max-height: 200px;
}

#deposit__popup .supported-deposit-currencies {
    padding: 0;
}

#deposit__popup .supported-deposit-currencies > select {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


#cashdesk__popup {
    min-height: 300px;
}


#cashdesk__popup .nav-tabs .nav-link {
    width: 50%;
}

.nav-tabs {
    border-color: var(--highlight-color);
}

.nav-tabs .nav-link {
    color: var(--primary-color-light);
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: var(--highlight-color);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #0000;
    border-color: var(--highlight-color);
    color: var(--highlight-color);
}



#promo-selector-section {
    padding-bottom: 30px;
    position: relative;
}

#promo-selector-section .selector-bar {
    position: absolute;
    bottom: 0;
    margin: 0;
    text-align: right;
}

.promotion-selector {
    padding: 0 .5% 15px .5%;
}

.selectable-promotion {
    display: inline-flex;
    vertical-align: text-top;
    cursor: pointer;
}

.selectable-body {
    display: inline-flex;
    width: 100%;
    padding: 5px 5px 5px 5px;
    border: solid 1px #fff6;
    border-radius: 5px;
}

.selected .selectable-body {
    border-color: var(--highlight-color);
}

.selectable-image {
    width: 40%;
    display: inline-block;
}

.selectable-content {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: white;
    user-select: none;
}

.selectable-description {
    font-size: 0.8em;
}

.btn.disabled-bsc {
    opacity: 0.5;
    pointer-events: none;
}

.selectable-image .selectable-banner {
	mask-image: url('/assets/images/masks/Pentagon_Mask.png');
	mask-position: center;
	mask-size: contain;
	position: relative;
	width: 100px;
	height: 100px;
	z-index: 100;
}

.selectable-image .selectable-banner::before {
	content: "";
    position: absolute;
	top: 0;
	left: 0;
    width: 100%;
	height: 100%;
	background-image: inherit;
    background-position: center center;
	background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    overflow: hidden;
}

.selectable-image .selectable-banner::after {
	content: "";
	background-color: var(--highlight-color-light);
	mask-image: url('/assets/images/masks/Pentagon_Mask_Border.png');
	mask-position: center;
	mask-size: contain;
    position: absolute;
	top: 0;
	left: 0;
    width: 100%;
	height: 100%;
    overflow: hidden;
}